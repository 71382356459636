<template>
    <div>
      <yardCheckHistory :checks="checks" />
    </div>
    </template>
<script>

import { mapGetters } from 'vuex';
import YardCheckHistory from '@/components/yardCheckHistory.vue';

export default {
  name: 'YardCheckView',
  components: { YardCheckHistory },
  computed: {
    ...mapGetters('check', ['checks']),
  },
  created() {
    this.$store.dispatch('check/getChecks');
  },
};
</script>
