var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('v-card-title',[_vm._v("Yard Check History")]),_c('v-data-table',{staticStyle:{"text-align":"left"},attrs:{"headers":_vm.headers,"items":_vm.checks,"items-per-page":50,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-minus',
        nextIcon: 'mdi-plus',
        'items-per-page-options': [50, 100, -1],
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }